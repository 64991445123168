.navbar{
  z-index: 10;
}
.login{
  max-width: 350px;
  width: 100%;
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  border: 1px solid #ccc;
  padding: 3rem;
  border-radius: 1rem;
  background-color: #FFF;
}
.signup{
  max-width: 350px;
  width: 100%;
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  border: 1px solid #ccc;
  border-radius: 1rem;
  background-color: #FFF;
}
.addnote{
  max-width: 800px;
  width: 100%;
  position: fixed;
  top:45%;
  left:50%;
  transform: translate(-50%,-50%);
  border: 1px solid #ccc;
  border-radius: 1rem;
  background-color: #FFF;
  padding: 2rem;
  z-index: 2;
}
.blurbackground{
  width: 100%;
  height: 100%;
  background-color: black;
  position: fixed;
  z-index: 1;
  top:0;
  left: 0;
  opacity: 0.4;
}
body{
  background-color: #F7F7F7;
}
.btn-form{
  width: 100%;
}
.note-data{
  width: 80vw;
  height: 70vh;
  background-color: #FFF;
  position: fixed;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  z-index: 2;
  border-radius: 8px;
  overflow: auto;
  padding: 2rem;
}
.search{
  width: auto;
  height: 40px;
  background-color: #FFF; 
  padding: 0 1rem;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 50px;
  box-sizing: border-box;
}
.search input{
  width:90%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 1rem;
}
.eye{
  margin-left: -28px;
}
.invalid{
  color: rgb(238, 83, 83);
}
.valid{
  color:rgb(50, 136, 50);
}
.passwordMatch{
  font-size: small;
  color: gray;
}
.passwordMatch p{
  margin: 0;
}
.warning{
  position: fixed;
  z-index: 2;
  top: 50%;
  left:50%;
  transform: translate(-50%,-50%);
  width: 250px;
  height: 250px;
  background-color: #FFF;
  color: black;
  border: 1px solid #ccc;
  border-radius: 10px;
}
.message{
  width: 100%;
  height: 100%;
  display: inline;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.landing{
  margin-top: 10vw;
}

.landing .right{
  text-align: center;
  position: relative;
  left: 50px;
}
.landing h1{
  font-weight: 700;
}
.landing p{
  opacity: 0.8;
}
.noteimg{
  width: 30vw;
  margin: auto;
  transform: rotate(344deg);
}
.logoutbtn{
  position: absolute;
  right: 8px;
  top:45px;
}
.note-data-tag{
  outline: none;
  border: none;
}
.note-data-title{
  outline: none;
  border: none;
  width: 90%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
i {
  cursor: pointer;
}
.modal{
  display: block;
}
.backgroundColorBlur{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.8;
}
.spinner{
  position: fixed;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.popup{
  display: none;
  z-index: 1;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 300px;
  height: auto;
  padding: 10px;
  border: 1px solid #3a3a3a;
  background-color: white;
  border-radius: 5px;
}
.popup button{
  width: 40%;
}
.card{
  margin: auto;
  height: 175px;
  overflow: hidden;
}
.card-about{
  margin: auto;
  height: 100px;
  overflow: hidden;
  margin: 10px;
}
.card-tag{
  position: absolute;
  bottom: -7px;
  left: 15px;
  width: 150px;
  overflow:hidden;
}
.youtube-thumbnail{
  background-color: #d4d4d4;
  padding: 10px;
  border-radius: 7px;
}
.youtube-thumbnail:hover{
  background-color: #c2c0c0;
}
.youtube-thumbnail>img{
  width: 20vw;
  object-fit: cover;
}

.card-text{
  height: 100px;
  overflow: hidden;
  cursor: pointer;
}
.time{
  position: absolute;
  bottom: -7px;
  right: 15px;
  opacity: 0.5;
}
.notedata-date{
  opacity: 0.5;
}
.notedata{
  position: sticky;
  bottom: -35px;
  background-color: white;
  padding-top: 10px;
  border-top: 1px solid #b9b8b8;
}
.noteDescription{
  white-space: pre-wrap;
  width: 100%;
  height: 47vh;
  border: none;
  outline: none;
}
.links{
  white-space: pre-wrap;
}
.notes{
margin-top: 10vh;
}
.cross{
  position: sticky;
  top: 10px;
}
.container-about{
  margin-top: 5rem;
}
.active{
  color: black;
  font-weight: 600;
}
.contacts>a{
  color:black;
  text-decoration: none;
}
@media only screen and (max-width: 800px) {
  .landing img{
    display: none;
  }
  .landing {
   margin-top: 25vh;
  }
  .landing .left{
     text-align: center;
  }
  .logoutbtn{
    position: static;
    right:0;
  }
  .addnote{
      max-width: 350px;
  }
  .notedata{
  display: flex;
  flex-direction: column;
  }
  .youtube-thumbnail>img{
  width: 100%;
  object-fit: cover;
}
}
